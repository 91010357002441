'use client';

import { SCHOOL_COOKIE_NAME } from '@magicschool/utils/constants/cookies';
import { createBrowserClient as supabaseCreateBrowserClient } from '@supabase/ssr';
import type { SupabaseClient } from '@supabase/supabase-js';
import type { Database } from '../../types/db';

export const createBrowserClient = <TDB extends Database>(): SupabaseClient<TDB> => {
  const supabaseUrl = process.env.NEXT_PUBLIC_SUPABASE_URL ?? 'http://localhost:54321';
  const supabaseKey = process.env.NEXT_PUBLIC_SUPABASE_ANON_KEY ?? 'fake-supabase-key';

  return supabaseCreateBrowserClient<TDB>(supabaseUrl, supabaseKey, {
    cookieOptions: {
      name: SCHOOL_COOKIE_NAME,
    },
  });
};
