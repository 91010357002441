import { renderDoWMDYT } from '@/util/date';
import type { OrgSyncPerson, OrgSyncPersonUpdated, OrgSyncResults } from '@magicschool/business-logic/edlink-sync';
import { logger } from '@magicschool/logger';
import type { MagicSchoolUser } from '@magicschool/supabase/user';
import { type SetField, createStoreSlice } from 'features/store/zustand';
import { getLatestOrgSyncResults } from './actions';

export type OrgSyncStore = {
  loading: boolean;
  orgId: string | null;
  lastSynced: string;
  usersAdded: OrgSyncPerson[];
  usersRemoved: OrgSyncPerson[];
  usersUpdated: OrgSyncPersonUpdated[];
  lastSyncHash: string | null;
  updatedUser: OrgSyncPersonUpdated | null;
  syncDetailsModalOpen: boolean;
  syncUserDetailsDrawerOpen: boolean;
  load: (user: MagicSchoolUser, locale: string) => void;
  open: (orgId: string | null | undefined) => void;
  setField: SetField<OrgSyncStore>;
};

const defaultState = {
  loading: true,
  orgId: null,
  usersAdded: [],
  usersRemoved: [],
  usersUpdated: [],
  lastSynced: '',
  lastSyncHash: null,
  updatedUser: null,
  syncDetailsModalOpen: false,
  syncUserDetailsDrawerOpen: false,
};

export const createOrgSyncStoreSlice = createStoreSlice('OrgSyncStoreData', defaultState, ({ get, set, setField }) => ({
  setField,
  open: (orgId) => {
    set({ orgId, syncDetailsModalOpen: true });
  },
  load: async (_, locale) => {
    const orgId = get().orgId;
    if (orgId) {
      const orgSync = await getLatestOrgSyncResults(orgId, 'full');
      if (orgSync.error) {
        logger.error('No sync results found for org', { orgId, error: orgSync.error });
        return;
      }

      const orgSyncData = orgSync.data as OrgSyncResults;
      const { orgSyncId, finish_date, people } = orgSyncData;
      const lastSynced = finish_date ? renderDoWMDYT(locale, finish_date) : '';
      set({
        loading: false,
        lastSynced,
        usersAdded: people?.peopleAdded,
        usersRemoved: people?.peopleRemoved,
        usersUpdated: people?.peopleUpdated,
        lastSyncHash: orgSyncId,
      });
    }
  },
}));
