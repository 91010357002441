import { useStore } from '@/features/store';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import type { ChangeEvent } from 'react';
import { FormattedMessage } from 'react-intl';
export const Name = () => {
  const {
    fullName,
    studentFriendlyName,
    setField
  } = useStore(({
    ProfileBuilderStoreData: s
  }) => ({
    fullName: s.fullName,
    studentFriendlyName: s.studentFriendlyName,
    setField: s.setField
  }), []);
  const handleFullNameChange = (event: ChangeEvent<HTMLInputElement>) => {
    setField('fullName')(event.target.value);
  };
  const handleStudentNameChange = (event: ChangeEvent<HTMLInputElement>) => {
    setField('studentFriendlyName')(event.target.value);
  };
  return <Box display="flex" flexDirection="column" gap={4} width="100%" maxWidth={320} data-sentry-element="Box" data-sentry-component="Name" data-sentry-source-file="Name.tsx">
      <TextField value={fullName} onChange={handleFullNameChange} label={<FormattedMessage id="profile-builder.name.full-name" />} placeholder="Jane Smith" variant="filled" data-sentry-element="TextField" data-sentry-source-file="Name.tsx" />
      <TextField value={studentFriendlyName} onChange={handleStudentNameChange} label={<FormattedMessage id="profile-builder.name.student-friendly-name" />} placeholder="Ms. Smith" variant="filled" data-sentry-element="TextField" data-sentry-source-file="Name.tsx" />
    </Box>;
};