import type { InitialStateProps } from '@/features/store/store';
import type { MessageModerationData, Room, RoomState, RoomStudent, Student, User } from '@magicschool/supabase/types';
import type { RealtimeChannel } from '@supabase/supabase-js';
import { broadcastFlaggedMessage, broadcastNewToolChat } from 'features/realtime/messages';
import { type SetField, createStoreSlice } from 'features/store/zustand';
import type { AppRouterInstance } from 'next/dist/shared/lib/app-router-context.shared-runtime';

type LoadProps = {
  room: Room;
  roomStudent: RoomStudent;
};

export type StudentStore = {
  loading: boolean;
  student: Student | undefined;
  user: User | undefined;
  currentRoomStudent: RoomStudent | null;
  currentRoom: Room | null;
  realtimeChannel: RealtimeChannel | null;
  load: (data: LoadProps) => void;
  onAssistantThreadMessage: (args: { threadId: number; tool_id: string; tool_uuid: string; message: string }) => void;
  onMessageModeration: (moderation: MessageModerationData) => void;
  setField: SetField<StudentStore>;
  handleRoomStateEvent: (roomId: string, roomState: `${RoomState}`, router: AppRouterInstance) => void;
  handleRoomStudentStateEvent: (
    roomId: string,
    { roomStudentId, state }: { roomStudentId: string; state: `${RoomState}` },
    router: AppRouterInstance,
  ) => void;
  createRoomStudentThread: (threadId: number) => void;
};

const defaultState = {
  currentRoom: null,
  currentRoomStudent: null,
  loading: true,
  realtimeChannel: null,
};

type InitialProps = Pick<InitialStateProps, 'studentData'>;

const saveUrlState = (roomId: string) => {
  const lockedUrl = new URL(`/s/rooms/${roomId}/locked`, window.location.origin);
  lockedUrl.searchParams.set('next', window.location.href);

  return lockedUrl;
};

const handleRoomStateEvent = (
  roomId: string,
  prevState: `${RoomState}` | undefined = 'locked',
  newState: `${RoomState}`,
  router: AppRouterInstance,
) => {
  const searchParams = new URLSearchParams(window.location.search);
  const nextSearchParam = searchParams.get('next');
  if (prevState === 'locked') {
    router.push(nextSearchParam ?? `/s/rooms/${roomId}/tools`);
  } else if (newState === 'locked') {
    const lockedUrl = saveUrlState(roomId);
    router.push(lockedUrl.href);
  } else if (newState === 'archived') {
    router.push(`/s/rooms`);
  }
};

export const createStudentStoreSlice = ({ studentData }: InitialProps) =>
  createStoreSlice(
    'StudentStoreData',
    { ...defaultState, student: studentData?.student, user: studentData?.user },
    ({ set, get, setField }) => ({
      setField,
      load: (data) => {
        set({ currentRoom: data.room, currentRoomStudent: data.roomStudent });
      },
      onAssistantThreadMessage: ({ tool_id, tool_uuid, message }) => {
        const { currentRoomStudent } = get();
        if (!currentRoomStudent) return;

        broadcastNewToolChat(currentRoomStudent.room_id, currentRoomStudent.id, tool_id, tool_uuid, message);
      },
      onMessageModeration: (moderation) => {
        const { currentRoom } = get();
        if (!currentRoom) return;

        const isFlagged = (moderation.data as any)?.flagged; // BENTODO: improve this typing
        if (!isFlagged) return;

        broadcastFlaggedMessage(currentRoom.id, moderation);
      },
      handleRoomStateEvent: (roomId, roomState, router) => {
        const previousRoomState = get().currentRoom?.state;
        set((s) => (s.currentRoom ? { currentRoom: { ...s.currentRoom, state: roomState } } : {}));

        handleRoomStateEvent(roomId, previousRoomState, roomState, router);
      },
      handleRoomStudentStateEvent: (roomId, { roomStudentId, state }, router) => {
        const previousRoomStudentState = get().currentRoomStudent?.state;
        if (get().currentRoomStudent?.id === roomStudentId) {
          set((s) => (s.currentRoomStudent ? { currentRoomStudent: { ...s.currentRoomStudent, state } } : {}));
        }

        handleRoomStateEvent(roomId, previousRoomStudentState, state, router);
      },
      createRoomStudentThread: (threadId: number) => {
        fetch(`/api/s/room_students/threads`, {
          method: 'POST',
          body: JSON.stringify({ threadId }),
        });
      },
    }),
  );
