import type { PostgrestSingleResponse } from '@supabase/supabase-js';
import { SupabaseError } from './errors';

export const throwOnError: <Result>(obj: PostgrestSingleResponse<Result>) => PostgrestSingleResponse<Result> & { error: null } = (obj) => {
  if (obj.error !== null) {
    throw new SupabaseError(obj);
  }
  return obj;
};

export const dataOnSuccess: <Result>(obj: PostgrestSingleResponse<Result>) => Result = (obj) => {
  return throwOnError(obj).data;
};
