import type { AssistantThreadWithMessagesAndLinks, ImageUrlMap } from 'features/assistant/types';
import type { DataSource } from 'features/history/constants';
import { type SetField, createStoreSlice } from 'features/store/zustand';
import { MILLISECONDS_IN_YEAR } from 'util/date';
import { ImmutableList, ImmutableMap } from 'util/immutable';

interface GetHistoryResponse {
  threads: AssistantThreadWithMessagesAndLinks[];
  imageUrlMap: [string, string][];
}

type Filters = {
  userId: string;
  assistantType: 'tool' | 'raina-chat';
  toolSlug?: string;
  startDate: string;
  endDate: string;
  searchTerms: string;
  saved?: boolean;
  shared?: boolean;
};

export type TeacherHistoryStore = {
  loading: boolean;
  imageUrlMap: ImageUrlMap;
  threads: ImmutableList<AssistantThreadWithMessagesAndLinks>;
  searchTerms: string;
  toolSlug: string | undefined;
  toolName: string | undefined;
  startDate: Date | null;
  endDate: Date | null;
  dataSource: DataSource;
  forceDataReload: boolean;
  setField: SetField<TeacherHistoryStore>;
  load: (filters: Filters, to: number, from: number) => Promise<void>;
};

const defaultState = {
  loading: true,
  imageUrlMap: ImmutableMap<string, string>(),
  threads: ImmutableList<AssistantThreadWithMessagesAndLinks>(),
  searchTerms: '',
  toolSlug: 'All',
  toolName: 'All',
  startDate: new Date(Date.now() - MILLISECONDS_IN_YEAR),
  endDate: new Date(),
  dataSource: 'all' as const,
  forceDataReload: false,
};

export const createTeacherHistoryStoreSlice = createStoreSlice('TeacherHistoryStoreData', defaultState, ({ set, setField }) => ({
  setField,
  load: async (filters, to, from) => {
    set({ loading: true });

    const response = await fetch<GetHistoryResponse>('/api/history', {
      method: 'POST',
      body: JSON.stringify({
        ...filters,
        to,
        from,
      }),
    });

    const data = await response.json();

    set({
      imageUrlMap: ImmutableMap(data.imageUrlMap),
      threads: ImmutableList(data.threads),
      loading: false,
      forceDataReload: false,
    });
  },
}));
