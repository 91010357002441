import { syncOrg } from '@/features/orgSync/actions';
import { AuthorizationError } from '@/features/server/actions/authErrors';
import { type Org, OrgType } from '@magicschool/supabase/types';
import type { ExtendedOrgRequest, ExtendedOrgResponse } from 'app/api/admin/orgs/[id]/route';
import type { OrgsSearchResponse } from 'app/api/admin/orgs/search/route';
import { type SetField, createStoreSlice } from 'features/store/zustand';
import toast from 'react-hot-toast';

export const orgTypes = Object.values(OrgType);
export type Domain = {
  idx: number;
  value: string;
  set_user_org_id: boolean;
};

export type AdminOrgsStore = {
  loading: boolean;
  syncing: boolean;
  lastSynced: string | null;
  upserting: boolean;
  orgs: OrgsSearchResponse['orgs'];
  currentOrg: ExtendedOrgResponse;
  orgModalOpen: boolean;
  currentDomain: Domain;
  domainModalOpen: boolean;
  load: () => void;
  search: () => Promise<void>;
  upsert: () => Promise<void>;
  open: (orgId: string) => Promise<void>;
  close: () => void;
  setField: SetField<AdminOrgsStore>;
  validate: () => void;
  doSync: () => Promise<void>;
  searchQuery: string;
  formErrors: Record<string, string> | null;
};

const emptyOrg = (): Org => ({
  id: '',
  name: '',
  type: orgTypes[0],
  logo_url: '',
  created_at: '',
  updated_at: '',
  auth_providers: [],
  edlink_access_token_id: '',
  edlink_integration_id: '',
});

export const emptyExtendedOrg = () => ({
  ...emptyOrg(),
  domains: [],
  emailCount: 0,
  enableRequireLogin: false,
  enableEdlinkSync: false,
  requireLoginDefault: false,
});

const emptyDomain = (): Domain => ({
  idx: 0,
  value: '',
  set_user_org_id: false,
});

const defaultState = {
  loading: true,
  syncing: false,
  lastSynced: '',
  upserting: false,
  orgs: [],
  currentOrg: emptyExtendedOrg(),
  orgModalOpen: false,
  currentDomain: emptyDomain(),
  domainModalOpen: false,
  searchQuery: '',
  formErrors: null,
};

export const createAdminOrgsStoreSlice = createStoreSlice('AdminOrgsStoreData', defaultState, ({ get, set, setField }) => ({
  setField,
  load: () => set({ ...defaultState, loading: false }),
  search: async () => {
    const { searchQuery } = get();
    if (searchQuery.length < 3) return;
    set({ loading: true, orgs: [] });

    const response = await fetch<OrgsSearchResponse>(`/api/admin/orgs/search?name=${encodeURIComponent(searchQuery)}`);
    const { orgs } = await response.json();

    set({ loading: false, orgs });
  },
  open: async (orgId: string) => {
    set({ loading: true, orgModalOpen: true, formErrors: {}, syncing: false });
    const response = await fetch<ExtendedOrgResponse>(`/api/admin/orgs/${orgId}`);
    const data = await response.json();
    set({ currentOrg: data, syncing: data.syncStatus === 'syncing', loading: false, lastSynced: data.lastSyncDate });
  },
  close: () => {
    set({ orgModalOpen: false, currentOrg: emptyExtendedOrg(), formErrors: null });
  },
  upsert: async () => {
    get().validate();
    const { currentOrg: org, search, formErrors } = get();
    if (!formErrors) {
      set({ upserting: true });
      await fetch<ExtendedOrgResponse>(`/api/admin/orgs${org.id ? `/${org.id}` : ''}`, {
        method: org.id ? 'PUT' : 'POST',
        body: JSON.stringify({ org } satisfies ExtendedOrgRequest),
        onSuccess: async ({ response }) => {
          const data = await response.json();
          toast.success(`Org successfully ${org.id ? 'updated' : 'created'}`, { duration: 4000 });
          set({ upserting: false, orgModalOpen: false, currentOrg: emptyExtendedOrg(), searchQuery: data.name });
          search();
        },
        responseErrorHandlers: {
          conflict: async ({ response }) => {
            const res = await response.json<{ error: string }>();
            const formErrors: Record<string, string> = {};
            if (res.error.indexOf('org_domain_key') >= 0) {
              formErrors.domain = 'Domain already exists.';
            } else {
              formErrors.name = 'Org name already exists.';
            }
            set({ upserting: false, formErrors: formErrors });
          },
        },
      });
    }
  },
  validate: () => {
    const { currentOrg } = get();
    const formErrors: Record<string, string> = {};
    if (!currentOrg.name?.trim()) {
      formErrors.name = 'Name is required';
    }
    set({ formErrors: Object.keys(formErrors).length ? formErrors : null });
  },
  doSync: async () => {
    set({ syncing: true });
    const { currentOrg } = get();
    const triggerId = await syncOrg(currentOrg.id);
    if (triggerId && !(triggerId instanceof AuthorizationError)) {
      toast.success(`Sync triggered successfully (${triggerId.data}). Please check back soon.`, { duration: 8000 });
    } else {
      toast.error(`Failed to trigger sync. ${(triggerId as AuthorizationError).message}`, { duration: 8000 });
      set({ syncing: false });
    }
  },
}));
