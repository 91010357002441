import type { ChatMessage } from 'features/chat/types';
import { getMaxRandomInt } from 'util/math';
import type { ParsedFile } from './types';

export function makeToolInputMessage(inputs: any, userId?: string, threadId?: number, toolSlug?: string): ChatMessage {
  toolSlug = toolSlug ?? 'undefined'; // TODO: maybe make constructor receive parameters when streaming
  return {
    role: 'user',
    user_id: userId,
    assistant_thread_id: threadId,
    content: [
      {
        type: 'tool_inputs',
        toolId: toolSlug,
        inputs,
      },
    ],
  };
}

export function makeToolOutputMessage(output: string, model: string, userId?: string, threadId?: number, toolSlug?: string): ChatMessage {
  toolSlug = toolSlug || 'undefined';
  return {
    role: 'assistant',
    user_id: userId,
    assistant_thread_id: threadId,
    content: [
      {
        type: 'tool_output',
        toolId: toolSlug,
        output,
        model,
      },
    ],
  };
}

export function makeBasicRequestMessage(input: string, userId?: string, threadId?: number, files?: ParsedFile[]): ChatMessage {
  return {
    role: 'user',
    id: getMaxRandomInt(),
    user_id: userId,
    assistant_thread_id: threadId,
    content: [
      {
        type: 'text',
        text: input,
        files,
      },
    ],
  };
}

export function makeBasicResponseMessage(output: string, model: string, userId?: string, threadId?: number): ChatMessage {
  return {
    role: 'assistant',
    user_id: userId,
    assistant_thread_id: threadId,
    content: [
      {
        type: 'text',
        text: output,
        model,
      },
    ],
  };
}
