import { RoomTeacherRole } from '@magicschool/supabase/types';
import type { Room } from '@magicschool/supabase/types';
import type { SetField } from 'features/store/zustand';

export type RoomActionsStore = RoomActionData & RoomActions;

type RoomActionData = {
  loading: boolean;
  deleteRoomModalOpen: boolean;
  deletingRoomText: string;
  room: Room;
  joinInfoModalOpen: boolean;
  joinInfoUrl: string;
  confirmRemoveModalOpen: boolean;
  joinInfoModalStep: 'codes' | 'options' | 'room' | 'student';
};

export type RoomActions = {
  loadRoom: (room: Room) => void;
  setField: SetField<RoomActionsStore>;
  openJoinInfoModal: (roomStudentId?: string) => void;
  updateRoom: (room: Room, onUpdate: (room: Room) => void) => Promise<void>;
  deleteRoom: (onDelete: (room: Room) => void) => Promise<void>;
};

export const defaultRoom: Room = {
  created_at: '',
  max_students: 250,
  require_login: false,
  id: '',
  join_code: '',
  name: '',
  grade_level: 'pre-k',
  state: 'active' as const,
  state_changed_at: '',
  status: 'active',
  teacher_join_code: '',
  creator_org_id: null,
  avatar_color: '',
};

export const defaultRoomWithTeachers = {
  ...defaultRoom,
  room_teachers: [],
};

export const roomTeacherRoleIntlMap: Record<RoomTeacherRole, string> = {
  [RoomTeacherRole.administrator]: 'role.administrator',
  [RoomTeacherRole.creator]: 'role.creator',
  [RoomTeacherRole.coteacher]: 'role.coteacher',
  [RoomTeacherRole.pending]: 'coteacher.pending',
  [RoomTeacherRole.rejected]: 'coteacher.rejected',
};
