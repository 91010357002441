import { Button, LoadingButton } from '@magicschool/ui/Buttons';
import Check from '@mui/icons-material/Check';
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';
import Box from '@mui/material/Box';
import { FormattedMessage } from 'react-intl';
import type { StepActionProps } from './Steps';
type DataStepActionsProps = Omit<StepActionProps, 'isNewUser'>;
export const DataStepActions = ({
  back,
  next,
  nextDisabled,
  saving,
  isLastStep
}: DataStepActionsProps) => <Box display="flex" gap={2} data-sentry-element="Box" data-sentry-component="DataStepActions" data-sentry-source-file="DataStepActions.tsx">
    {back && <Button variant="outlined" ordinal="secondary" onClick={back} startIcon={<KeyboardArrowLeft />}>
        <FormattedMessage id="back" />
      </Button>}
    {next && <LoadingButton variant="contained" ordinal="secondary" onClick={next} disabled={saving || nextDisabled} loading={saving} endIcon={isLastStep ? <Check /> : <KeyboardArrowRight />}>
        <span>
          <FormattedMessage id={isLastStep ? 'profile-builder.data-step.get-started' : 'next'} />
        </span>
      </LoadingButton>}
  </Box>;