import type { ToolCustomization as BaseToolCustomization, Room, RoomTeacher, RoomTool } from '@magicschool/supabase/types';
import type { FormElementProps } from 'components/FormElements/types';
import type { SafeToolInfo } from 'features/tools/DTO';
import { ImmutableMap } from 'util/immutable';

export type FieldVisibility = 'hidden' | 'visible';

export type ToolCustomizationJsonConfig = {
  inputs: Record<string, { value: string | Record<string, string>; visibility: FieldVisibility }>;
  tool_title?: string;
  tool_description?: string;
};

export type ToolCustomization = Omit<BaseToolCustomization, 'json_config'> & {
  json_config: ToolCustomizationJsonConfig;
};

export type RoomToolWithCustomizations = RoomTool & { tool_customization: ToolCustomization | null };

export type CreateOrUpdateTool = { tool_slug: string; tool_uuid: string; tool_customization: ToolCustomization | null };

export type RoomPostOrPutRequest = {
  name: string;
  maxStudents: number;
  gradeLevel: string;
  tools: CreateOrUpdateTool[];
  requireLogin: boolean;
};

export const toToolCustomization = (tc: BaseToolCustomization): ToolCustomization => {
  return {
    ...tc,
    json_config: tc.json_config as unknown as ToolCustomizationJsonConfig,
  };
};

export const emptyToolCustomization = (toolDetails: SafeToolInfo, defaultGradeLevel: string): ToolCustomization => {
  const emptyTool: ToolCustomization = {
    id: '',
    name: '',
    tool_slug: toolDetails.tool.slug,
    tool_uuid: toolDetails.tool.id,
    teacher_id: '',
    json_config: {
      tool_title: toolDetails.tool.title,
      tool_description: toolDetails.tool.description,
      inputs: toolDetails.fields.reduce(
        (acc, field) => ({ ...acc, [field.name]: { value: field.initialValue, visibility: 'visible' } }),
        {},
      ),
    },
    created_at: '',
  };
  if (emptyTool.json_config.inputs.gradeLevel && defaultGradeLevel) {
    emptyTool.json_config.inputs.gradeLevel.value = defaultGradeLevel;
    emptyTool.json_config.inputs.gradeLevel.visibility = 'hidden';
  }
  return emptyTool;
};

export const generateValidToolCustomization = (
  fields: FormElementProps[],
  existingCustomizations: ToolCustomization,
): ToolCustomization => {
  const updatedInputs = ImmutableMap(
    fields.map((field) => {
      const updatedInput = existingCustomizations.json_config.inputs[field.name] || { value: field.initialValue, visibility: 'visible' };
      return [field.name, updatedInput];
    }),
  );
  return {
    ...existingCustomizations,
    json_config: {
      inputs: updatedInputs.toObject(),
      tool_title: existingCustomizations.json_config.tool_title,
      tool_description: existingCustomizations.json_config.tool_description,
    },
  };
};

/**
 * Tastes great AND is less filling!
 * A lite version of the Rooms table entity.
 *
 * This is intended for usage in all of the table views or lite summary views of rooms
 * creator_room_teacher: is always the room_teachers row of the creator
 * viewing_room_teacher: could be an admin, coteacher, or pending/rejected coteacher
 *
 * If you need all the expensive stuff (students, tools, moderation, etc), you should use the full RoomDetailsResponse type
 */
export type RoomWithBasics = {
  room: Room;
  id: string;
  student_count: number;
  creator_room_teacher: RoomTeacher;
  viewing_room_teacher: RoomTeacher | null;
};
