import type { RoomState } from '@magicschool/supabase/types';
import type { GetRoomResponse } from 'app/api/rooms/route';
import type { RoomWithBasics } from 'features/rooms/types';
import { type SetField, createStoreSlice } from 'features/store/zustand';

type StateFilter = keyof typeof RoomState | 'all';
export type RoomListStore = {
  loading: boolean;
  rooms: RoomWithBasics[];
  stateFilter: StateFilter;
  load: () => Promise<void>;
  setField: SetField<RoomListStore>;
};

const defaultState = {
  loading: true,
  rooms: [],
  stateFilter: 'active' as StateFilter,
};

export const createRoomListStoreSlice = createStoreSlice('RoomListStoreData', defaultState, ({ set, setField }) => ({
  setField,
  load: async () => {
    set({ ...defaultState, loading: true });
    const response = await fetch<GetRoomResponse>(`/api/rooms`);
    const rooms = await response.json();
    set({ loading: false, rooms });
  },
}));
