import { type AuthFormStore, createAuthFormStoreSlice } from '@/app/(public)/auth/authFormStore';
import { type JoinStore, createJoinStoreSlice } from '@/app/(student)/s/join/joinStore';
import { type OrgDashboardStore, createOrgDashboardStoreSlice } from '@/app/(teacher)/(metabase)/orgDashboardStore';
import {
  type AdminModerationSettingsStore,
  createAdminModerationSettingsStoreSlice,
} from '@/app/(teacher)/admin/(admin-access)/moderation-settings/moderationSettingStore';
import {
  type SystemMessageStore,
  createEditSystemMessageStoreSlice,
} from '@/app/(teacher)/admin/(admin-access)/system/editSystemMessageStore';
import { type ToolListStore, createToolListStoreSlice } from '@/app/(teacher)/admin/(admin-access)/tools/toolListStore';
import { type ProfileBuilderStore, createProfileBuilderStoreSlice } from '@/components/ProfileBuilder/ProfileBuilderStore';
import { ImmutableList } from '@/util/immutable';
import { type SiteConfigs, defaultSiteConfigs } from '@magicschool/business-logic/site-configs';
import type { OrgAttribute, OrgToolAccessOverride, UserAttribute, UserNudges, UserToolAccessOverride } from '@magicschool/supabase/types';
import type { MagicSchoolUser, SupabaseUser } from '@magicschool/supabase/user';
import { type StudentStore, createStudentStoreSlice } from 'app/(student)/s/(protected)/studentStore';
import { type AdminUsersStore, createAdminUsersStoreSlice } from 'app/(teacher)/admin/users/adminUsersStore';
import { type OrgRoomsStore, createOrgRoomsStoreSlice } from 'app/(teacher)/o/rooms/orgRoomsStore';
import { type OrgUsersStore, createOrgUsersStoreSlice } from 'app/(teacher)/o/users/orgUsersStore';
import { type RoomDetailsStore, createRoomDetailsStoreSlice } from 'app/(teacher)/rooms/[id]/roomDetailsStore';
import { type RoomCoTeacherStore, createRoomJoinStoreSlice } from 'app/(teacher)/rooms/coTeacher/roomCoTeacherStore';
import { type RoomListStore, createRoomListStoreSlice } from 'app/(teacher)/rooms/roomsStore';
import { type TeacherHistoryStore, createTeacherHistoryStoreSlice } from 'components/OutputHistory/teacherHistoryStore';
import { type RoomSettingsStore, createRoomSettingsStoreSlice } from 'components/RoomSettings/roomSettingsStore';
import { type EditToolStore, createEditToolStoreSlice } from 'features/admin/tools/editToolStore';
import { type AuthStore, createAuthStoreSlice, emptyUser } from 'features/auth/authStore';
import { type ChatStore, createChatStoreSlice } from 'features/chat/chatStore';
import type { FeatureFlags } from 'features/featureFlags';
import { type NotificationStore, createNotificationStoreSlice } from 'features/notifications/notificationsStore';
import { type OrgSyncStore, createOrgSyncStoreSlice } from 'features/orgSync/store';
import { createRoomActionsStoreSlice } from 'features/room/store';
import type { RoomActionsStore } from 'features/room/types';
import { type ToSBannerStore, createToSBannerStoreSlice } from 'features/tosBanner/tosBannerStore';
import { type SideBarStore, createSideBarStoreSlice } from 'layout/MainLayout/Sidebar/sidebarStore';
import { type AdminOrgsStore, createAdminOrgsStoreSlice } from '../../app/(teacher)/admin/orgs/adminOrgsStore';
import { type NudgeStore, createNudgeStoreSlice } from '../nudges/nudgeStore';
import type { StudentUser } from '../server/jwt/decodeStudentJWT';
import { type SiteConfigStore, createSiteConfigStoreSlice } from '../siteConfig/siteConfigStore';
import { type ToolsStore, createToolsPageStoreSlice } from '../tools/toolsStore';
import type { CentralizedStore } from './zustand';

export type FullState = {
  AuthFormStoreData: AuthFormStore;
  AuthStoreData: AuthStore;
  StudentStoreData: StudentStore;
  AdminModerationSettingsStoreData: AdminModerationSettingsStore;
  AdminUsersStoreData: AdminUsersStore;
  AdminOrgsStoreData: AdminOrgsStore;
  JoinStoreData: JoinStore;
  TeacherHistoryStoreData: TeacherHistoryStore;
  ChatStoreData: ChatStore;
  OrgUsersStoreData: OrgUsersStore;
  OrgDashboardStoreData: OrgDashboardStore;
  OrgRoomsStoreData: OrgRoomsStore;
  OrgSyncStoreData: OrgSyncStore;
  RoomSettingsStoreData: RoomSettingsStore;
  RoomDetailsStoreData: RoomDetailsStore;
  RoomActionsStoreData: RoomActionsStore;
  RoomListStoreData: RoomListStore;
  NotificationStoreData: NotificationStore;
  ToSBannerStoreData: ToSBannerStore;
  SideBarStoreData: SideBarStore;
  RoomCoTeacherStoreData: RoomCoTeacherStore;
  ToolListStoreData: ToolListStore;
  SystemMessageStoreData: SystemMessageStore;
  EditToolStoreData: EditToolStore;
  SiteConfigStoreData: SiteConfigStore;
  ToolsStoreData: ToolsStore;
  ProfileBuilderStoreData: ProfileBuilderStore;
  NudgeStoreData: NudgeStore;
};

export type InitialStateProps = {
  studentData?: StudentUser;
  userData?: MagicSchoolUser;
  userAttributes?: UserAttribute[];
  orgAttributes?: OrgAttribute[];
  userToolAccessOverrides?: UserToolAccessOverride[];
  orgToolAccessOverrides?: OrgToolAccessOverride[];
  session?: SupabaseUser;
  featureFlags?: FeatureFlags;
  siteConfigs?: SiteConfigs;
  completedNudges?: UserNudges['completed_nudges'];
};

export const createInitialState = ({
  studentData,
  userData = emptyUser(),
  session,
  siteConfigs = defaultSiteConfigs,
  userAttributes,
  orgAttributes,
  userToolAccessOverrides,
  orgToolAccessOverrides,
  completedNudges,
}: InitialStateProps) => {
  const authStore = createAuthStoreSlice({
    userData,
    session,
    userAttributes,
    orgAttributes,
    userToolAccessOverrides,
    orgToolAccessOverrides,
  });
  const siteConfigStore = createSiteConfigStoreSlice({ siteConfigs });
  const studentStore = createStudentStoreSlice({ studentData });
  const nudgeStore = createNudgeStoreSlice({ completedNudges: ImmutableList<string>(completedNudges) });
  const createState: CentralizedStore<FullState> = (setFull, getFull): FullState => ({
    AuthFormStoreData: createAuthFormStoreSlice({ setFull, getFull }),
    AuthStoreData: authStore({ setFull, getFull }),
    StudentStoreData: studentStore({ setFull, getFull }),
    NudgeStoreData: nudgeStore({ setFull, getFull }),
    AdminModerationSettingsStoreData: createAdminModerationSettingsStoreSlice({ setFull, getFull }),
    AdminUsersStoreData: createAdminUsersStoreSlice({ setFull, getFull }),
    AdminOrgsStoreData: createAdminOrgsStoreSlice({ setFull, getFull }),
    JoinStoreData: createJoinStoreSlice({ setFull, getFull }),
    TeacherHistoryStoreData: createTeacherHistoryStoreSlice({ setFull, getFull }),
    ChatStoreData: createChatStoreSlice({ setFull, getFull }),
    OrgUsersStoreData: createOrgUsersStoreSlice({ setFull, getFull }),
    OrgDashboardStoreData: createOrgDashboardStoreSlice({ setFull, getFull }),
    OrgSyncStoreData: createOrgSyncStoreSlice({ setFull, getFull }),
    RoomSettingsStoreData: createRoomSettingsStoreSlice({ setFull, getFull }),
    RoomDetailsStoreData: createRoomDetailsStoreSlice({ setFull, getFull }),
    RoomListStoreData: createRoomListStoreSlice({ setFull, getFull }),
    NotificationStoreData: createNotificationStoreSlice({ setFull, getFull }),
    SystemMessageStoreData: createEditSystemMessageStoreSlice({ setFull, getFull }),
    ToSBannerStoreData: createToSBannerStoreSlice({ setFull, getFull }),
    OrgRoomsStoreData: createOrgRoomsStoreSlice({ setFull, getFull }),
    SideBarStoreData: createSideBarStoreSlice({ setFull, getFull }),
    RoomActionsStoreData: createRoomActionsStoreSlice({ setFull, getFull }),
    RoomCoTeacherStoreData: createRoomJoinStoreSlice({ setFull, getFull }),
    ToolListStoreData: createToolListStoreSlice({ setFull, getFull }),
    EditToolStoreData: createEditToolStoreSlice({ setFull, getFull }),
    SiteConfigStoreData: siteConfigStore({ setFull, getFull }),
    ToolsStoreData: createToolsPageStoreSlice({ setFull, getFull }),
    ProfileBuilderStoreData: createProfileBuilderStoreSlice({ setFull, getFull }),
  });
  return createState;
};
